body {
  font-family: "Roboto", sans-serif;
  color: #1e1e1e;
}

h1 {
  margin-block-start: 50px;
  font-size: 70px;
  font-size: 38px;
  line-height: 1.4;
}

h3 {
  font-size: 32px;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

p {
  font-size: 16px;
  color: rgb(105, 105, 105);
  line-height: 185%;
  letter-spacing: 0.05em;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.icon {
  height: 32px;
  width: 32px;
  margin-bottom: 1rem;
}

.icon svg {
  height: 100%;
  width: 100%;
}
.puffsContainer {
  display: flex;
  margin-top: 2.5rem;
  gap: 2rem;
}

.singleValuePuff {
  background: #f7f7fa;
  border-radius: 8px;
  padding: 2rem;
  width: 32%;
  font-size: 14px;
  line-height: 22px;
}

.git:hover {
  background-color: rgb(95, 212, 228) !important;
  transition: 0.7s;
}

@media (max-width: 875px) {
  h1 {
    font-size: 29px;
  }

  h3 {
    font-size: 24px;
  }

  .puffsContainer {
    flex-wrap: wrap;
  }

  .singleValuePuff {
    width: 100%;
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  #about {
    padding: 0 !important;
  }

  .about-me {
    flex-direction: column;
  }

  .about-me div {
    width: 100% !important;
    padding: 2rem 1rem !important;
  }
}

.header {
  width: 100%;
  display: flex;
  box-shadow: 0px 0 0 black;
  transition-duration: 0.3s;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999;
}

.menu-overlay {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: black;
  opacity: 0;
  position: absolute;
  transition-duration: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
}

.menu-open .menu-overlay {
  opacity: 0.9;
  z-index: 99;
}

/* CSS */
.header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0px -11px 18px black;
}

.logo a {
  color: #1e1e1e;
}

.logo a:hover {
  text-decoration: none;
  color: #1e1e1e;
}
