.description a {
  color: coral;
  display: inline-block;
  position: relative;
}
.description a:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  transition: 0.3s ease 0s, 0.3s ease 0s;
  width: 0;
}
.description a:hover {
  transition: 0.5s;
  color: black;
}
.description a:hover:after {
  width: 50%;
  left: 23%;
}
