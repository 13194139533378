.view {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  border: 4px solid black !important;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: white;
}
.view .mask,
.view .content {
  width: 300px;
  height: 300px;
  position: absolute;
  background-color: hsla(186, 90%, 90%, 0.9);
  top: 0;
  left: 0;
  border-radius: 1px;
}

.view h2 {
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 30px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  margin: 50px 0 0 0;
}
.view p {
  line-height: 110%;
  letter-spacing: 0, 03em;
  font-size: 17px;
  position: relative;
  color: black;
  padding: 10px 30px 0px 20px;
  text-align: center;
  height: 75px;
}

.view-first img {
  height: 290px;
  width: 290px;
}
.view-first img {
  transition: all 0.2s linear;
}
.view-first .mask {
  opacity: 0;

  transition: all 0.4s ease-in-out;
}
.view-first h2 {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.view-first p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}

.view-first:hover img {
  transform: scale(1.2);
}
.view-first:hover .mask {
  opacity: 1;
}
.view-first:hover h2,
.view-first:hover p {
  opacity: 1;
  transform: translateY(0px);
}

@media (max-width: 700px) {
  .view,
  .view .mask,
  .view .content {
    width: 271px;
    height: 281px;
  }
  .view h2 {
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 30px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
  }
  .view-first img {
    height: 270px;
    width: 270px;
  }
}
